<template>
<div class="advisory">
  <Headline type="h2" classes="subline1 text-center mb-4" :text="$props.data.data.attributes.name" />
  <template v-for="answer in this.first" :key="answer.id">
    <div class="advisory-button">
      <a class="btn mb-4 btn-white" v-html="compileText(answer.question)"  v-on:click.prevent="getSecond({optionen: answer.Optionen, headline: answer.Headline})"/>
    </div>
  </template>
  <div class="advisory-result flex flex-col" v-if="show_second">
    <Icons classes="my-3 text-center" type="angle-down" />
    <Headline type="h2" classes="subline1 text-center mb-4" :text="second.headline" />
    <template v-for="answerTwo in second.optionen" :key="answerTwo.id">
      <div class="advisory-button">
        <!-- Seite -->
        <router-link :to="answerTwo.seiten.data.attributes.Slug" class="btn mb-4 btn-white" v-if="answerTwo.seiten.data && !answerTwo.beratung.data" v-html="compileText(answerTwo.answer)" />
        <!-- beratung -->
        <a class="btn mb-4 btn-white" v-if="answerTwo.beratung.data" v-html="compileText(answerTwo.answer)" v-on:click.prevent="getThree(answerTwo.beratung.data.id)" />
      </div>
    </template>
  </div>
  <div class="advisory-result flex flex-col" v-if="show_three">
    <Icons classes="my-3 text-center" type="angle-down" />
    <Headline type="h2" classes="subline1 text-center mb-4" :text="this.three.attributes.name" />
    <template v-for="answerThree in this.three.attributes.Antworten[0].Optionen" :key="answerThree.id">
      <div class="advisory-button">
        <router-link :to="answerThree.seiten.data.attributes.Slug" class="btn mb-4 btn-white" v-html="compileText(answerThree.answer)"/>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const Headline = defineAsyncComponent(() => import('@/components/Headline'));
const Icons = defineAsyncComponent(() => import('@/components/Icons'));

import ApiService from "@/services/apiService";
import qs from "qs";
import filterService from "@/services/filterService";

export default {
  name: "Advisory",
  props: ['data'],
  data() {
    return {
      first: [],
      show_second: false,
      show_three: false,
      second: [],
      three: [],
      queryOptions: {
        populate: {
          Antworten: {
            populate: {
              Optionen: {
                populate: '*',
              }
            }
          },
        },
        sort: [],
        filters: {},
        locale: [],
        fields: []
      }
    }
  },
  components: {
    Headline,
    Icons
  },
  methods: {
    getFirst() {
      let query = qs.stringify(this.queryOptions);
      ApiService.get(`beratungs/${this.$props.data.data.id}?${query}`).then(res => {
        this.first = res.data.data.attributes.Antworten
      })
    },
    getSecond(data) {
      this.show_second = true
      this.second = data
      this.show_three = false
    },
    getThree(data) {
      this.show_three = true
      let query = qs.stringify(this.queryOptions);
      ApiService.get(`beratungs/${data}?${query}`).then(res => { this.three = res.data.data, console.log(res.data.data) })
    },
    compileText(text) {
      return filterService.textCompile(text, {emoji: false, openLinksInNewWindow: false}).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
    }
  },
  created() {
    this.getFirst()
  }
}
</script>

<style lang="scss" scoped>
.advisory {
  .advisory-result {
    .font-icons {
      color: var(--pumpkin);
      font-size: var(--font-size-36);
    }
  }
}
</style>
